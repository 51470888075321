<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>

    <v-stepper v-model="e1" non-linear>
      <v-stepper-header>
        <v-stepper-step editable :complete="e1 > 1" step="1">Informações básicas</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step editable :complete="e1 > 2" step="2">Domínios autorizados</v-stepper-step>

        <v-divider></v-divider>

        <!-- <v-stepper-step editable step="3">Administradores</v-stepper-step> -->
        <v-divider></v-divider>

        <!-- <v-stepper-step editable step="4">Pagamento</v-stepper-step> -->
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <form-organization
            action="new"
            ref="formOrg"
            @input="organizationData.data = $event"
            :organization="organizationData"
          ></form-organization>

          <v-btn color="primary" @click="e1 = 2">Continuar</v-btn>

          <v-btn text @click="clear">Cancelar</v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <form-organization-config
            action="new"
            ref="formOrgConfig"
            @input="organizationData.data = $event"
            :organization="organizationData"
          ></form-organization-config>

          <v-btn color="primary" @click="create">Salvar</v-btn>

          <v-btn text @click="clear">Cancelar</v-btn>
          <!-- {{ organizationData.data }} -->
        </v-stepper-content>

        <v-stepper-content step="3">
          <!-- <table-organization-admin @input="organizationData.data.domains = $event" :edit="true"></table-organization-admin> -->

          <v-btn color="primary" @click="e1 = 1">Continuar</v-btn>

          <v-btn text>Cancelar</v-btn>
        </v-stepper-content>
        <v-stepper-content step="4">
          <!-- <form-organization-plan action="edit" :organization="organizationData"></form-organization-plan> -->
          <v-btn color="primary" @click="e1 = 1">Salvar</v-btn>

          <v-btn text @click="clear">Cancelar</v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  data: () => ({
    organizationData: {
      data: {
        billing: {
          email: ''
        },
        domains: [],
        company: {
          document: {
            id: '',
            type: 'CNPJ'
          },
          name: {
            trade: '',
            legal: ''
          },
          segment: ''
        },
        address: {
          state: '',
          neighborhood: '',
          number: '',
          country: '',
          city: '',
          street: '',
          zip: '',
          additional: ''
        }
      },
      metadata: {
        id: ''
      }
    },
    e1: 1,
    page: {
      title: 'Cadastro de organização'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Instituição',
        disabled: false,
        to: '/'
      },
      {
        text: 'Cadastro',
        disabled: false,
        to: 'new'
      }
    ]
  }),
  methods: {
    ...mapMutations(['setLoading']),

    clear () {
      this.$refs.formOrg.clear()
      this.$refs.formOrgConfig.clear()
      this.e1 = 1
    },

    create () {
      if (!this.$refs.formOrg.isValid() || !this.$refs.formOrgConfig.isValid()) {
        this.$store.dispatch('alert', {
          color: 'red',
          msg: 'Campos inválidos! Confira e tente novamente.'
        })
        return
      }

      this.setLoading(true)

      const self = this
      this.$axios
        .post('/organizations', this.organizationData.data)
        .then(response => {
          self.$store.dispatch('alert', { msg: 'Cadastrado com sucesso' })
        })
        .catch(error => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', {
                color: 'red',
                msg: errosArray[item][0]
              })
            })
          } else {
            self.$store.dispatch('alert', {
              color: 'red',
              msg: 'Erro na rede, tente novamente!'
            })
          }
        })
        .finally(() => {
          this.setLoading(false)
          this.sending = false
        })
    }
  }
}
</script>
